import React from 'react';
import Menu from '../components/Menu';
import './Home.css'
import Footer from '../components/Footer';
import './Accrediteds.css'

import carrier from '../assets/credenciadas/carrier.png'
import consul from '../assets/credenciadas/consul.png';
import daikin from '../assets/credenciadas/daikin.png';
import elgin from '../assets/credenciadas/elgin.png';
import fujitsu from '../assets/credenciadas/fujitsu.png';
import gree from '../assets/credenciadas/gree.png';
import lg from '../assets/credenciadas/lg.png';
import midea from '../assets/credenciadas/midea.png';
import panasonic from '../assets/credenciadas/panasonic.png';

function Accrediteds() {
    return (
        <>
            <Menu/>
            <div className='accreditedsPage'>
                <div className='container'>
                    <div className='title'>
                        <h5>Soluções em climatização</h5>
                        <h1>Credenciadas</h1>
                    </div>
                    <div className='accreditedsLogos'>
                        <img src={carrier} alt="Home" className='home'/>
                        <img src={consul} alt="Home" className='home'/>
                        <img src={daikin} alt="Home" className='home'/>
                        <img src={elgin} alt="Home" className='home'/>
                        <img src={fujitsu} alt="Home" className='home'/>
                        <img src={gree} alt="Home" className='home'/>
                        <img src={lg} alt="Home" className='home'/>
                        <img src={midea} alt="Home" className='home'/>
                        <img src={panasonic} alt="Home" className='home'/>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Accrediteds;