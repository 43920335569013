import React from 'react';
import Menu from '../components/Menu';
import './Contact.css'
import Footer from '../components/Footer';
import { useState } from 'react';
import emailjs from '@emailjs/browser';  
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const handleChangeName = ({target}) => {
        setName(target.value);
    }
    const handleChangeEmail = ({target}) => {
        setEmail(target.value);
    }
    const handleChangeSubject = ({target}) => {
        setSubject(target.value);
    }
    const handleChangeMessage = ({target}) => {
        setMessage(target.value);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (name === '' || email === '' || subject === '' || message === '') {
            alert("Preencha todos os campos.")
            return;
        }

        const serviceId = "service_xp4bu73";
        const templateId = "template_4qoty7t";
        const templateParams = {
            from_name: name,
            email: email,
            subject: subject,
            message: message,
        }
        const publicKey = "GQ2nKoNFGMYcAh15m";
        
        emailjs.send(serviceId, templateId, templateParams, publicKey)
        .then((response) => {
            console.log("Email enviado", response.status, response.text);
            toast.success("E-mail enviado com sucesso!", {theme: "colored"});
            setName(''); 
            setEmail('');
            setSubject('');
            setMessage('');
        }, (error) => {
            console.log("Erro:", error);
            toast.error(`E-mail não enviado ${error}`, {theme: "colored"});
        });
        
    }
    

    return (
        <>
            <Menu/>
            <div className='contactPage'>
                <ToastContainer />
                <form className='contactForm' onSubmit={handleSubmit}>        
                    <div className='title'>
                        <h1>Entre em contato conosco e tire suas dúvidas</h1>
                    </div>
                    <div className='nameAndEmail'>
                        <label>
                            Seu nome:
                            <input type="text" value={name} onChange={handleChangeName} />
                        </label>
                        <label>
                            Seu e-mail:
                            <input type="e-mail" value={email} onChange={handleChangeEmail} />
                        </label>
                    </div>
                    <label>
                        Assunto:
                        <input type="text" value={subject} onChange={handleChangeSubject} />
                    </label>
                    <label>
                        Mensagem:
                        <textarea  className='message' type="textArea" value={message} onChange={handleChangeMessage} />
                    </label>
                    <input className="submitButton" type="submit" value="Enviar mensagem"/>
                </form>
            </div>
            <Footer/>
        </>
    )
}

export default Contact;