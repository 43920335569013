import React from 'react';
import './Footer.css';

import { Nav, NavItem, NavLink } from 'reactstrap';

function Footer() {
    return (
      <>
      <div className='footer'>
        <Nav>
          <NavItem className='itemNav'>
            <NavLink href="#">
                Home
            </NavLink>
          </NavItem>
          <NavItem className='itemNav'>
            <NavLink href="#">
                Credenciadas
            </NavLink>
          </NavItem>
          <NavItem className='itemNav'>
            <NavLink href="#">
                Serviços
            </NavLink>
          </NavItem>
          <NavItem className='itemNav'>
            <NavLink href="#">
                Contato
            </NavLink>
          </NavItem>
        <div className='buttons'>
          <NavItem className='itemNav'>
              <NavLink href="#">
                <i class="fa fa-whatsapp"></i>
              </NavLink>
            </NavItem>
            <NavItem className='itemNav'>
              <NavLink href="http://www.instagram.com/milclima.climatizacao">
                <i class="fa fa-instagram"></i>
              </NavLink>
            </NavItem>
            <NavItem className='itemNav'>
              <NavLink href="#">
                <i class="fa fa-facebook"></i>
              </NavLink>
            </NavItem>
          </div>
          <div className='copyright'>
            <NavItem className='itemNav'>
              <NavLink href="#">
                  {/* <div className='firstLine'>desenvolvido por</div>
                  Filipe Milcharek e Kazumi Orita */}
              </NavLink>
            </NavItem>
          </div>
          </Nav>
      </div>
      </>
    )
}

export default Footer;