import './App.css';
import Home from './pages/Home';
import Accrediteds from './pages/Accrediteds';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Team from './pages/Team';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Specialties from './pages/Specialties';
import PMOC from './pages/PMOC';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} /> 
        </Routes>
        <Routes>
          <Route path="/credenciadas" element={<Accrediteds />} /> 
        </Routes>
        <Routes>
          <Route path="/servicos" element={<Services />} /> 
        </Routes>
        <Routes>
          <Route path="/especialidades" element={<Specialties />} /> 
        </Routes>
        <Routes>
          <Route path="/pmoc" element={<PMOC />} /> 
        </Routes>
        <Routes>
          <Route path="/contato" element={<Contact />} /> 
        </Routes>
        <Routes>
          <Route path="/team" element={<Team />} /> 
        </Routes>
      </Router>
    </div>
  );
}

export default App;
