import React from 'react';
import Menu from '../components/Menu';
import './PMOC.css'
import Footer from '../components/Footer';
import WhatsAppButton from '../components/WhatsAppButton';

import pmoc from '../assets/pmoc.png';

function PMOC() {
    return (
        <div className='pmocpage'>
            <Menu/>
            <div className='pmocHeader'>
                <img src={pmoc} alt="pmoc" className='pmocImg'/>
            </div>
            <div className='pmoc'>
                <div>
                    <h1>PMOC</h1>
                    <p className='subtitle'>Saiba o que é, qual a obrigatoriedade, benefícios e responsabilidades legais na aplicação do PMOC.</p>
                    <p>No dia 04 de janeiro deste ano, foi sancionada a Lei 13.589/2018 que tornou obrigatória a implementação do Plano de Manutenção, Operação e Controle (PMOC), já exigida pela Portaria 3523 do Ministério da Saúde e também pela lei municipal de Natal, lei 5.714 de 30 de março de 2006.</p>
                    <p>Mesmo depois da obrigatoriedade, poucos empresários sabem do que se trata o PMOC e como providenciar este Plano de Manutenção Operação e Controle para os sistemas de climatização de suas empresas.</p>
                    <p>Por isso separamos todas as informações necessárias para que você possa conhecer e se preparar para implementação. Vamos lá!</p>
                </div>
            </div>
            <div className='pmoc'>
                <div>
                    <h1>O que é o PMOC?</h1>
                    <p>O PMOC é um Plano de Manutenção, Operação e Controle que regulamenta a manutenção de forma preventiva e corretiva, de instalações e equipamentos de sistemas de climatização de ambientes, visando à eliminação ou minimização de riscos potenciais à saúde dos ocupantes.</p>
                    <p>Nesse plano são determinadas o número de ocupantes de cada ambiente climatizado, a carga térmica a ser estipulada em cada equipamento, o tipo de atividade que é exercida no local, as datas de verificação e execução de correções técnicas em cada ponto do sistema de climatização.</p>
                    <p>Os padrões, valores, parâmetros, normas e procedimentos necessários à garantia da boa qualidade do ar interior, são regulamentados pela Agência Nacional de Vigilância Sanitária (ANVISA) e Associação Brasileira de Normas Técnicas (ABNT).</p>
                </div>
            </div>
            <div className='pmoc'>
                <div>
                    <h1>Para quem é obrigatório o PMOC?</h1>
                    <p>Todas as empresas, tanto públicas quanto privadas, que possuem ambientes climatizados artificialmente com carga térmica igual ou superior a 60.000 Btus, devem dispor de um PMOC.</p>
                    <p>Esta Lei, também, se aplica aos ambientes climatizados de uso restrito, tais como aqueles dos processos produtivos, laboratoriais, hospitalares e outros, que deverão obedecer a regulamentos específicos.</p>
                    <span className='listTitle'>Ar-condicionado de janela também é obrigatório PMOC?</span>
                    <p>A obrigatoriedade é independentemente do equipamento utilizado, seja Split, janela ou central.</p>              
                    <p>É importante ressaltar que se a soma de todos os equipamentos do ambiente superar a carga térmica de 60.000 Btus, o PMOC passa a ser exigido.</p>              
                </div>
            </div>
            <div className='pmoc'>
                <div>
                    <h1>Quem vai fiscalizar o PMOC?</h1>
                    <p>Os órgãos competentes para fiscalização do PMOC são: Vigilância Sanitária, ANVISA e Delegacia do Trabalho, que serão responsáveis por inspecionar equipamentos e instalações dos ambientes para garantir uma boa qualidade do ar.</p>
                    <span className='listTitle'>Quais as penalidades para a empresa que não implementar o PMOC?</span>
                    <p>A empresa que não cumprir as determinações estará sujeita a multas que variam entre R$2 mil a R$1,5 milhão de reais, dependendo do grau de risco, recorrência e tamanho do estabelecimento.</p>              
                    <span className='listTitle'>Quais os benefícios em adotar o PMOC?</span>
                    <p>Implementando o PMOC na sua empresa, além de ficar livre das multas e penalidades descritas no item anterior, você terá uma série de benefícios como:</p>
                    <ul>
                        <li><i class="fa fa-check-square-o"></i>Aumento da qualidade do ar nos ambientes climatizados;</li>
                        <li><i class="fa fa-check-square-o"></i>Redução dos riscos potenciais à saúde dos ocupantes;</li>
                        <li><i class="fa fa-check-square-o"></i>Melhora da eficiência dos equipamentos de climatização;</li>
                        <li><i class="fa fa-check-square-o"></i>Redução do consumo de energia;</li>
                        <li><i class="fa fa-check-square-o"></i>Evitar gastos não programados com manutenções;</li>
                        <li><i class="fa fa-check-square-o"></i>Aumento da vida útil dos equipamentos e componentes.</li>
                    </ul>              
                </div>
            </div>
            <div className='pmoc'>
                <div>
                    <span className='listTitle'>Quer mais informações de como elaborar e implementar o PMOC?</span>
                    <p> Tire suas dúvidas com nossa equipe de especialistas clicando no botão abaixo:</p>
                    <div className='whatsapp'>
                        <WhatsAppButton size={'large'} title={"Falar com especialista"}/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default PMOC;