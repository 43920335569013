import React from 'react';
import Menu from '../components/Menu';
import './Home.css'
import WhatsAppButton from '../components/WhatsAppButton';
import Footer from '../components/Footer';

import home from '../assets/home-2.png';
import casa from '../assets/casa.png';
import empresa from '../assets/empresa.png';
import industria from '../assets/industria.png';

function Home() {
    return (
        <>
        <Menu/>
        <div className='homepage'>
            <div className='confortSession'>
                <div>
                    <h5>Soluções em climatização</h5>
                    <h1>Seu conforto, nossa solução</h1>
                    <p>Manutenção e instalação de sistemas de climatização.</p>
                    <WhatsAppButton size={'large'} title={"Fale com a gente pelo WhatsApp"}/>
                </div>
                <img src={home} alt="Home" className='home'/>
            </div>
            <div className='servicesSession'>
                <div className='card'>
                    <img src={industria} alt="Industria" className='typeOfService'/>
                    <h1>Industrial</h1>
                    <p>
                        Trabalhamos com ar-condicionados de grande porte, sendo Chillers, Fan coil, Selfs entre outros equipamentos.
                    </p>
                </div>
                <div className='card'>
                    <img src={empresa} alt="Empresa" className='typeOfService'/>
                    <h1>Comercial</h1>
                    <p>
                        Além de serviços de instalação e manutenção de ar condicionados comerciais, também atendemos refrigeração comercial, incluindo mercados, prédios e edifícios. 
                        <br />
                        Área de risco - alpinismo industrial. 
                    </p>
                </div>
                <div className='card'>
                    <img src={casa} alt="Casa" className='typeOfService'/>
                    <h1>Residencial</h1>
                    <p>
                        Trabalhamos com instalação e manutenção de todos os modelos e marcas de ar-condicionados residenciais.
                    </p>
                </div>
            </div>
            <div className='pmocSession'>
                <h1>PMOC</h1>
                Devido a importância da qualidade do ar nos ambientes de trabalho, foi regulamentado através da Portaria 3253/1998, bem como da Lei Federal 13.589, critérios de qualidade mínima nestes ambientes.
                <br />
                <br />
                Portanto, todos os sistemas de climatização em prédios públicos ou privados, devem conter um Plano de Manutenção, Operação e Controle – PMOC e manter um técnico habilitado para controlar e monitorar o sistema.
                <br />
                <br />
                Nós atendemos esse tipo de serviço e podemos te ajudar!
                <br />
                <br />
                Caso a sua empresa ainda não esteja de acordo e não tenha o PMOC, ela está irregular com a ANVISA, leis ambientais e de segurança do trabalho, estando sujeita ao pagamento de multa, dependendo do risco da gravidade, recorrência e tamanho do estabelecimento.
                <br />
                <br />
                <WhatsAppButton size={'large'} title={"Quero regularizar o PMOC!"} pmoc/>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default Home;