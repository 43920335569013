import React from 'react';
import Menu from '../components/Menu';
import './Specialties.css'
import Footer from '../components/Footer';

import vrf from '../assets/vrf.png';
import multisplit from '../assets/multisplit.png';
import fancoil from '../assets/fancoil.png';

function Specialties() {
    return (
        <>
        <div className='specialtiesPage'>
            <Menu/>
            <div className='vrfSession'>
                <div>
                    <h1>VRF</h1>
                    <p className='subtitle'>Máximo de conforto térmico com redução de consumo elétrico.</p>
                    <p>Sistema de ar condicionado central composto por uma unidade condensadora externa ligada à múltiplas evaporadoras (unidades internas), podendo ter grandes distâncias entre elas.</p>
                    <p>Ideal para flexibilidade nos pontos de instalação tendo maior liberdade para projetos arquitetonicos e otimizando espaços, além da possibilidade de ambientes climatizados individualmente.</p>
                    <span className='listTitle'>PRINCIPAIS BENEFÍCIOS</span>
                    <ul>
                        <li><i class="fa fa-check-square-o"></i>Condensadora única, promove economia de espaço</li>
                        <li><i class="fa fa-check-square-o"></i>Manutenção remota - Controle remoto - Internet (App)</li>
                        <li><i class="fa fa-check-square-o"></i>Permite maior distância entre condensadora e evaporadoras</li>
                        <li><i class="fa fa-check-square-o"></i>Controle central de todas E.V.A</li>
                        <li><i class="fa fa-check-square-o"></i>Economia de energia</li>
                    </ul>
                </div>
                <img src={vrf} alt="VRF" className='vrf'/>
            </div>
            <div className='multiSplitSession'>
                <img src={multisplit} alt="multiSplitImg" className='multiSplitImg'/>
                <div>
                    <h1>MULTI SPLIT DUTADO</h1>
                    <p>PERFORMANCE PARA CLIMATIZAÇÃO DE GRANDES AMBIENTES.</p>
                    <p>O sistema dutado vem com a possibilidade de estar em um ou mais ambientes, mantendo uma temperatura homogênea ideal para ambientes com grande fluxo de pessoas.</p>
                    <span className='listTitle'>PRINCIPAIS BENEFÍCIOS</span>
                    <ul>
                        <li><i class="fa fa-check-square-o"></i>Evitar ruído de fluxo de ar</li>
                        <li><i class="fa fa-check-square-o"></i>Evitar zonas de ar</li>
                        <li><i class="fa fa-check-square-o"></i>Sem limitação de pé direito</li>
                        <li><i class="fa fa-check-square-o"></i>Otimização da mistura de ar externo/renovação</li>
                        <li><i class="fa fa-check-square-o"></i>Permite instalação de variados filtros (G4, M5, F8, entre outros)</li>
                    </ul>
                </div>
            </div>
            <div className='fanCoilSession'>
                <div>
                    <h1>FAN COIL</h1>
                    <p className='subtitle'>SISTEMA AMPLAMENTE UTILIZADO EM SHOPPINGS CENTERS E HOSPITAIS.</p>
                    <p>Consiste na climatização central indireta, onde utiliza-se água gelada ou quente circulante para climatização do ambiente.</p>
                    <span className='listTitle'>PRINCIPAIS BENEFÍCIOS</span>
                    <ul>
                        <li><i class="fa fa-check-square-o"></i>Sem limitação de pé direito</li>
                        <li><i class="fa fa-check-square-o"></i>Evitar zonas de ar</li>
                        <li><i class="fa fa-check-square-o"></i>Evitar ruído de fluxo de ar</li>
                        <li><i class="fa fa-check-square-o"></i>Otimização da mistura de ar externo/renovação</li>
                        <li><i class="fa fa-check-square-o"></i>Permite instalação de variados filtros (G4, M5, F8, entre outros)</li>
                        <li><i class="fa fa-check-square-o"></i>Economia de energia</li>
                    </ul>
                </div>
                <img src={fancoil} alt="Fancoil" className='fancoil'/>
            </div>
        </div>
            
            <Footer/>
        </>
    )
}

export default Specialties;