import React from 'react';
import { Button } from 'reactstrap';
import './WhatsAppButton.css';

function WhatsAppButton({size, title, pmoc}) {
    const goToWhatsApp = () => {
        window.location.href = pmoc ? `https://wa.me/5551991204815?text=Ola,%20Quero%20regularizar%20o%20PMOC` : `https://wa.me/5551991204815?text=Ola,%20Gostaria%20de%20um%20or%C3%A7amento`;
    }

    return (
        <Button 
            onClick={goToWhatsApp}
            className='whatsappButton' 
            color='light' 
            style={size === 'large' ? {width: "400px"} : {width: "254px"}}>
                {title}
            <i class="fa fa-whatsapp"></i>
        </Button>
    )
}

export default WhatsAppButton;