import React from 'react';
import Menu from '../components/Menu';
import './Services.css'
import Footer from '../components/Footer';
import servicos from '../assets/servicos.png'

function Services() {
    return (
        <>
            <Menu/>
            <div className='servicesPage'>
                <div className='mainSession'>
                    <div>
                        <h5>Soluções em climatização</h5>
                        <h1>Seu conforto, nossa solução</h1>
                        <p className='servicesSubtitle'>Manutenção e instalação de sistemas de climatização.</p>
                    </div>
                </div>
                
                <div className='maintenanceSession'>
                    <img src={servicos} alt="Services" className='servicos'/>
                    <div className='maintenanceSessionText'>
                        <h1>Manutenção, Operação, Controle.</h1>
                        <p>A execução do Plano de Manutenção, Operação e Controle (PMOC) é essencial para garantir que seus aparelhos de refrigeração funcionem corretamente e prolonga a sua eficácia, além de gerar economia. Saiba mais conosco!</p>
                    </div>
                </div>
                
                <div className='teamSession'>
                    <div className='teamSessionText'>
                        <h1>Profissionais capacitados, qualidade garantida.</h1>
                        <p>Temos equipe capacitada, formada por Engenheiro, Técnico e Mecânico.</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Services;