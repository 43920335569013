import React from 'react';
import Menu from '../components/Menu';
import './Team.css'
import WhatsAppButton from '../components/WhatsAppButton';
import Footer from '../components/Footer';

import equipe1 from '../assets/equipe1-min.png';
import equipe2 from '../assets/equipe2-min.png';
import equipe3 from '../assets/equipe3-min.png';
import equipe4 from '../assets/equipe4-min.png';
import equipe5 from '../assets/equipe5-min.png';
import equipe6 from '../assets/equipe6-min.png';
import equipe7 from '../assets/equipe7-min.png';
import equipe8 from '../assets/equipe8-min.png';
import equipe9 from '../assets/home-2.png';

function Team() {
    return (
        <>
        <Menu/>
        <div className='teamPage'>
            <div className='teamSession'>
                <div className='photo'>
                    <img src={equipe1} alt="Industria" className='photoImage'/>
                </div>
                <div className='photo'>
                    <img src={equipe2} alt="Casa" className='photoImage'/>
                </div>
                <div className='photo'>
                    <img src={equipe3} alt="Casa" className='photoImage'/>
                </div>
                <div className='photo'>
                    <img src={equipe4} alt="Casa" className='photoImage'/>
                </div>
                <div className='photo'>
                    <img src={equipe5} alt="Casa" className='photoImage'/>
                </div>
                <div className='photo'>
                    <img src={equipe6} alt="Casa" className='photoImage'/>
                </div>
                <div className='photo'>
                    <img src={equipe7} alt="Casa" className='photoImage'/>
                </div>
                <div className='photo'>
                    <img src={equipe8} alt="Casa" className='photoImage'/>
                </div>
                <div className='photo'>
                    <img src={equipe9} alt="Casa" className='photoImage'/>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default Team;